import { adminClient } from "@api/admin/AdminClient";
import CodeTextArea from "@components/admin/CodeTextArea";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import { createSuccessToast } from "@components/ToastContainer";
import { queryApi } from "@hooks/api";
import { AppBar, Box, Button, Card, Dialog, Fab, IconButton, Toolbar, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { ContentResponse } from "@reshopper/admin-client";
import { useState } from "react";

export default withAdminMenuLayout(() => {
    const [contents, refreshContents] = queryApi(
        async (options) => await adminClient().adminContentsGet(options),
        []);

    const [tradeTerms, refreshTradeTerms] = queryApi(
        async (options) => (await adminClient().adminLatestTradeTermsGet(options)),
        []);

    const [currentCode, setCurrentCode] = useState<string>();
    const [currentId, setCurrentId] = useState<string>();

    const [isEditingTradeTerms, setIsEditingTradeTerms] = useState<boolean>(false);

    async function onSave() {
        if (isEditingTradeTerms) {
            await adminClient().adminTradeTermsPost({
                body: {
                    terms: currentCode || ""
                }
            })
        } else {
            await adminClient().adminContentsUpsertContentPost({
                body: {
                    id: currentId,
                    markdownCode: currentCode || ""
                }
            });
        }

        onClose();
        refreshContents();
        refreshTradeTerms();
        createSuccessToast("Saved!");
    }

    async function onDelete() {
        if (!currentId)
            throw new Error("Content must be selected.");

        const isConfirmed = window.confirm("Are you sure you want to delete this content?");
        if (!isConfirmed)
            return;

        await adminClient().adminContentsContentDelete({
            body: {
                id: currentId
            }
        });

        onClose();
        refreshContents();
        createSuccessToast("Deleted!");
    }

    function onClose() {
        setCurrentCode(undefined);
        setCurrentId(undefined);
        setIsEditingTradeTerms(false);
    }

    return <>
        <Dialog
            open={currentCode !== undefined}
            fullScreen
            onClose={() => onClose()}
        >
            <AppBar style={{
                position: 'relative'
            }}>
                <Toolbar>
                    <Box>
                        <Typography variant="h3">{isEditingTradeTerms ? "Version " + tradeTerms?.versionId : ""}</Typography>
                    </Box>
                    <Box flexGrow={1} />
                    {!isEditingTradeTerms &&
                        <Button variant="outlined" color="inherit" startIcon={<DeleteIcon />} onClick={() => onDelete()}>
                            Delete
                        </Button>}
                    <Button variant="outlined" color="inherit" startIcon={<SaveIcon />} onClick={() => onSave()} style={{
                        marginLeft: 8
                    }}>
                        Save
                    </Button>
                    <IconButton edge="start" color="inherit" onClick={() => onClose()} style={{
                        marginLeft: 32
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <CodeTextArea
                code={currentCode}
                onCodeChanged={setCurrentCode}
                height="95vh"
                language="markdown" />
        </Dialog>
        <Box display="flex"
            style={{
                justifyContent: 'space-between',
            }}
        >
            <Typography variant="h4">
                Content management
            </Typography>
            <Box
                style={{
                    display: 'flex',
                }}
            >
                <Button
                    color="secondary"
                    variant="contained"
                    style={{marginRight: 8}}
                    onClick={() => {
                        setIsEditingTradeTerms(true);
                        setCurrentCode(tradeTerms?.terms as string)
                    }}
                >
                    Trade Terms
                </Button>
                <Fab
                    color="primary"
                    onClick={() => setCurrentCode("")}
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Box>
        {contents?.map(content =>
            <ContentItem content={content} onClick={() => {
                setCurrentCode(content.markdownCode);
                setCurrentId(content.id);
            }} />)}
    </>;
});

function ContentItem(props: {
    content: ContentResponse,
    onClick: () => void
}) {
    const [isHovered, setIsHovered] = useState(false);

    return <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        key={props.content.id}
        style={{
            marginTop: 32,
            position: 'relative',
            cursor: 'pointer',
            border: '1px solid ' + (isHovered ? '#0D47A1' : 'transparent'),
        }}>
        <CodeTextArea
            readonly
            language="markdown"
            height={250}
            code={props.content.markdownCode}
            style={{
                margin: -1
            }} />
        <Box
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                opacity: isHovered ? 0.01 : 0.4,
                backgroundColor: 'white'
            }}
            onClick={() => props.onClick()}
        />
    </Card>
}
