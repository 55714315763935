import { Box } from "@material-ui/core";
import Editor, { EditorProps } from "@monaco-editor/react";

export default function CodeTextArea(props: {
    code: string|null|undefined,
    readonly?: boolean,
    width?: number|string,
    height?: number|string,
    language?: "markdown"|"json"|"javascript",
    style?: React.CSSProperties,
    onCodeChanged?: (code: string) => void,
    options?: EditorProps["options"]
}) {
    if(typeof window === "undefined")
        return <></>;

    const width = props.width ?? "100%";
    const height = props.height ?? "80vh";

    const code = props.code || "";

    return <Box 
        style={{
            border: '1px solid #ddd',
            borderRadius: 3,
            overflow: 'hidden',
            position: 'relative',
            ...(props.style || {})
        }}
    >
        <Editor
            height={height}
            width={width}
            language={props.language || "json"}
            value={code}
            defaultValue={code}
            options={{
                autoIndent: "full",
                formatOnPaste: true,
                formatOnType: true,
                readOnly: props.readonly,
                emptySelectionClipboard: true,
                folding: true,
                foldingStrategy: "auto",
                showFoldingControls: "always",
                wordWrap: "on",
                automaticLayout: true,
                contextmenu: false,
                lineDecorationsWidth: 0,
                scrollbar: {
                    vertical: "hidden",
                    horizontal: "hidden",
                    verticalScrollbarSize: 0
                },
                ...(props.options ?? {})
            }}
            onChange={(value) => {
                if(!props.onCodeChanged) {
                    return;
                }

                props.onCodeChanged(value || "");
            }}
        />
    </Box>;
}