import { adminClient } from "@api/admin/AdminClient";
import AdminMenu from "@components/admin/AdminMenu";
import Seo from "@components/Seo";
import ToastContainer from "@components/ToastContainer";
import DateFnsUtils from '@date-io/date-fns';
import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { deleteJwtCookies, getJwtCookiePayload, hasAdministratorAccessThroughJwt, isJwtTokenExpired, JwtCookiePayload } from "@utils/swaggerClientUtils";
import { da } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { adminTheme } from "../../theme";

export function withAdminMenuLayout(Component: (props: any) => JSX.Element | null) {
    return withAdminLayout((props: any) => {
        const [jwtCookiePayload, setJwtCookiePayload] = useState<JwtCookiePayload | null>(null);
        const [loadContent, setLoadContent] = useState(false);
        const [isRenewing, setIsRenewing] = useState(false);

        useEffect(() => {
            const jwtPayload = getJwtCookiePayload();
            setJwtCookiePayload(jwtPayload);

            async function loadPageIfUserHasAccess() {
                if (isRenewing) {
                    return;
                }

                if (jwtPayload && isJwtTokenExpired(jwtPayload)) {
                    try {
                        setIsRenewing(true);
                        await adminClient().adminAuthenticationRefreshJwtPost({});
                        setJwtCookiePayload(jwtCookiePayload);
                        setIsRenewing(false);
                        return;
                    } catch (error) {
                        deleteJwtCookies();
                        setJwtCookiePayload(jwtCookiePayload);
                        setIsRenewing(false);
                        console.log(error);
                    }
                }

                if (window.location.href.indexOf("admin") === -1) {
                    setLoadContent(true);
                    return;
                } else if (hasAdministratorAccessThroughJwt(jwtPayload)) {
                    setLoadContent(true);
                    return;
                }
                else {
                    setLoadContent(false);
                    return;
                }
            }

            loadPageIfUserHasAccess();
        }, [isRenewing]);

        return <Box
            style={{
                display: 'flex',
                height: '100vh',
                maxWidth: "100vw",
                overflow: "hidden"
            }}
        >
            <AdminMenu jwtCookiePayload={jwtCookiePayload} />

            <Box
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    padding: 32,
                    flexDirection: 'column',
                    width: "100vw",
                    height: "100vh",
                    overflow: "auto",
                }}
            >
                <Box style={{ minWidth: "1000px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={da}>
                        <Component {...props} />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
        </Box>
    });
}

function withAdminLayout(Component: (props: any) => JSX.Element | null) {
    return (props: any) => <>
        <Helmet>
            <link
                href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&amp;display=swap"
                rel="stylesheet"
            />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=1" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=1" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=1" />
            <link rel="manifest" href="/site.webmanifest?v=1" />

        </Helmet>
        <ThemeProvider theme={adminTheme}>
            <CssBaseline />
            <Seo noIndex />
            <Component {...props} />
            <ToastContainer />
        </ThemeProvider>
    </>;
}