import Logo from "@components/Logo";
import { Box, Button, Collapse, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DescriptionIcon from "@material-ui/icons/Description";
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation } from "@reach/router";
import { useState } from "react";
import { deleteJwtCookies, getBasePath, isProd, JwtCookiePayload } from "../../utils/swaggerClientUtils";
import styles from "./AdminMenu.module.scss";


type NavigationItemType = {
	title: string,
	url?: string,
	onClick?: () => void,
	icon?: OverridableComponent<any> | (() => any),
	subItems?: NavigationItemType[]
};

function NavigationItem(props: NavigationItemType) {
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const item = props;
	const Icon = item.icon;

	const isSelected = item.url ?
		pathname.indexOf(item.url || "") === 0 :
		false;

	const inner = <>
		{Icon &&
			<ListItemIcon style={{
				minWidth: 35,
				opacity: isSelected ? 1 : 0.75
			}}>
				<Icon color={isSelected ? "primary" : "inherit"} />
			</ListItemIcon>}

		<ListItemText className={styles.listItemText} primary={item.title} style={{
			marginRight: 8,
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		}} />

		{props.subItems &&
			<IconButton size="small" onClick={ev => {
				setIsOpen(!isOpen);
				ev.stopPropagation();
			}}>
				{isOpen ?
					<ExpandLess /> :
					<ExpandMore />}
			</IconButton>}
	</>

	return <>
		{(!!props.url || !!props.onClick || !!props.subItems) ?
			<ListItem
				selected={isSelected}
				button
				onClick={ev => {
					if (item.onClick)
						return item.onClick();

					if (item.url) {
						window.location.href = item.url;
						return;
					}

					setIsOpen(!isOpen);
					ev.stopPropagation();
				}}
			>
				{inner}
			</ListItem> :
			<ListItem
				selected={isSelected}
				button={false}
			>
				{inner}
			</ListItem>}
		{props.subItems && <Collapse
			in={isOpen}
			timeout="auto"
			unmountOnExit
		>
			<List component="div" disablePadding style={{
				opacity: 0.75
			}}>
				{props.subItems.map((subItem, index) =>
					<NavigationItem
						key={index}
						{...subItem} />)}
			</List>
		</Collapse>}
	</>;
}


export default (props: {
	jwtCookiePayload: JwtCookiePayload | null
}) => {
	const navigationItems: Array<NavigationItemType | (() => JSX.Element)> = [
		{
			title: "Web content",
			url: "/admin/content",
			icon: DescriptionIcon
		},
		() => <Divider />, 
		{
			title: "Log out",
			onClick: () => {
				deleteJwtCookies();
				window.location.href = '/admin';
			},
			icon: AccountCircleIcon
		}
	];

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const print = useMediaQuery('print');
	if (print)
		return null;

	return <Box className={styles.animated + " " + (isMenuOpen ? styles.hovered : "")}>


		<Box>
			<Box className={styles.logoContainer}>
				<Logo className={styles.logo} variant="icon-text-brown-vertical" />
			</Box>

			<Button
				onClick={() => setIsMenuOpen((prev) => !prev)}
				style={{ padding: "13px 0", minWidth: "55px", width: "100%", marginTop: "10px", borderRadius: 0, boxShadow: "none" }}
				color="primary"
				variant="contained">
				{isMenuOpen ? <ArrowBackIcon /> : <MenuIcon />}
			</Button>
		</Box>

		<Divider />

		<List component="div" style={{ paddingTop: 0 }}>
			{navigationItems.map((item, index) => {
				const key = "menu_" + index;
				if (typeof item === "function") {
					const Component = item;
					return <Component key={key} />;
				}

				return <NavigationItem {...item} key={key} />;
			})}
		</List>
		{!isProd() && isMenuOpen &&
			<Typography style={{ fontSize: 12, padding: 8 }}>{getBasePath()}</Typography>}
	</Box>
}